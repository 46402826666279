import { Container, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import FileUploader from "../../components/common/FileUploader";
import AccountHeader from "./AccountHeader";
import NoData from "../../components/common/NoData";
import ConfirmModal from "../../components/common/ConfirmModal";
import {
  ADD_IMAGE_TO_GALLERY,
  REMOVE_IMAGE_FROM_GALLERY,
  SET_RESTAURANT_PRIMARY_IMAGE,
  UPDATE_GALLERY_IMAGE_ORDER,
} from "../../graphql";
import { useMutation } from "@apollo/client";
import { Severity } from "../../constants";
import { Gallery } from "@/app/types";
import PhotoList from "../../components/gallery/PhotoList";
import Spinner from "../../components/common/Spinner";

export default function PhotoGallery() {
  const { t } = useTranslation();

  const [addImageToGallery] = useMutation(ADD_IMAGE_TO_GALLERY);
  const [removeImageFromGallery] = useMutation(REMOVE_IMAGE_FROM_GALLERY);
  const [setRestaurantPrimaryImage] = useMutation(SET_RESTAURANT_PRIMARY_IMAGE);
  const [updateGalleryImageOrder] = useMutation(UPDATE_GALLERY_IMAGE_ORDER);
  const {
    restaurant,
    gallery,
    setGallery,
    setSnackBarMessageProps,
    isGlobalLoading,
  } = useContext(AppContext);
  const [files, setFiles] = useState<File[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ index: -1, open: false });

  if (!restaurant) {
    return null;
  }

  const uploadFiles = (files: File[]) => {
    const uploadedFiles: Gallery["items"] = [];
    const failedUploads: number[] = [];
    const readers: FileReader[] = [];
    for (let i = 0; i < files.length; i++) {
      readers[i] = new FileReader();
      readers[i].onload = async (e) => {
        const data = e?.target?.result;
        if (data) {
          setIsLoading(true);
          try {
            const { data: newImage } = await addImageToGallery({
              variables: {
                alt: "",
                data,
                type: files[i].type,
                uuid: restaurant.uuid,
              },
            });

            const uploadedFile = {
              uuid: newImage.addImageToGallery!.uuid,
              image: newImage.addImageToGallery!,
              order: gallery!.items.length,
              createdAt: newImage.addImageToGallery!.createdAt,
              updatedAt: newImage.addImageToGallery!.updatedAt,
            };
            uploadedFiles.push(uploadedFile);

            if (failedUploads.length + uploadedFiles.length === files.length) {
              setFiles((files) =>
                files.filter((_, index) => failedUploads.includes(index)),
              );
              setGallery?.({
                ...gallery!,
                items: [...gallery!.items, ...uploadedFiles],
              });
              setSnackBarMessageProps?.({
                message: t("common.success"),
                severity: Severity.Success,
              });
              setIsLoading(false);
            }
          } catch (error) {
            failedUploads.push(i);
            setSnackBarMessageProps?.({
              message: t("photoGallery.uploadError", {
                error: (error as Error).message,
              }),
              severity: Severity.Error,
            });
            if (failedUploads.length + uploadedFiles.length === files.length) {
              setIsLoading(false);
              if (!gallery?.primary?.data) {
                await handleSetDefaultImage(0);
              }
            }
          }
        }
      };
      readers[i].readAsDataURL(files[i]);
    }
  };

  const handleClose = () => {
    setDeleteModal({ index: -1, open: false });
  };

  const handleSetDefaultImage = async (index: number) => {
    const item = gallery?.items[index];
    if (!item) {
      return;
    }
    try {
      setIsLoading(true);
      await setRestaurantPrimaryImage({ variables: { ...item.image } });
      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
      setGallery?.({
        ...gallery!,
        primary: item.image,
      });
    } catch (error) {
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setOrder = async (items: Gallery["items"]) => {
    const sortedItem = items.map((item, index) => ({
      ...item,
      order: index,
    }));

    setGallery?.({
      ...gallery!,
      items: sortedItem,
    });

    try {
      await Promise.all(
        sortedItem.map((item) =>
          updateGalleryImageOrder({
            variables: {
              uuid: restaurant.uuid,
              imageUUID: item.image.uuid,
              order: item.order,
            },
          }),
        ),
      );

      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });
    }
  };

  const handleDeleteImage = async (index: number) => {
    const item = gallery?.items[index];
    if (!item) {
      return;
    }
    try {
      await removeImageFromGallery({ variables: { uuid: item.image.uuid } });
      setGallery?.({
        ...gallery!,
        items: gallery!.items.filter((_, i) => i !== index),
      });
      setDeleteModal({ index, open: false });
    } catch (error) {
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Typography variant="h6" marginTop={2}>
          {t("photoGallery.title")}
        </Typography>
        {(gallery?.items?.length ?? 0) >= 8 ? (
          <NoData
            title={t("photoGallery.maxPhotosTitle")}
            message={t("photoGallery.maxPhotosText")}
            topMargin="0"
          />
        ) : (
          <FileUploader
            uploadFiles={uploadFiles}
            files={files}
            setFiles={setFiles}
            isLoading={isLoading || isGlobalLoading}
            existingFiles={gallery?.items}
          />
        )}
        <Paper sx={{ paddingX: 8, paddingY: 4, marginTop: 4 }}>
          {gallery?.items?.length ? (
            <PhotoList
              gallery={gallery}
              setOrder={setOrder}
              setDeleteModal={setDeleteModal}
              handleSetDefaultImage={handleSetDefaultImage}
              isLoading={isLoading || !!isGlobalLoading}
            />
          ) : isGlobalLoading ? (
            <Spinner />
          ) : (
            <NoData
              title={t("photoGallery.noPhotosTitle")}
              message={t("photoGallery.noPhotosText")}
              topMargin="0"
            />
          )}
        </Paper>
        {deleteModal.open && (
          <ConfirmModal
            actionTitle={t("common.delete")}
            handleConfirm={() => handleDeleteImage(deleteModal.index)}
            title={t("common.deleteConfirmTitle")}
            message={t("photoGallery.deleteImage")}
            inputIsOpen={deleteModal.open}
            inputHandleClose={handleClose}
          />
        )}
      </Container>
    </div>
  );
}
