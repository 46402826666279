import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FormEvent, MouseEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import { CountryList, Cuisine, Severity } from "../../constants";
import { Restaurant, RestaurantGQLPayload } from "../../types/Restaurant";
import { UPDATE_RESTAURANT_INFORMATION } from "../../graphql";
import { useMutation } from "@apollo/client";

const sectionStyle = { paddingX: 4, paddingY: 6, marginTop: 2 };
export default function ProfileEdit() {
  const { t } = useTranslation();
  const [updateRestaurantInformation, { loading, error }] = useMutation(
    UPDATE_RESTAURANT_INFORMATION,
  );
  const [hasScrolled, setHasScrolled] = useState(false);
  const { restaurant, setSnackBarMessageProps, setRestaurant } =
    useContext(AppContext);
  const [updatedRestaurant, setUpdatedRestaurant] = useState<Restaurant>(
    restaurant!,
  );

  useEffect(() => {
    if (restaurant && !updatedRestaurant?.uuid) {
      setUpdatedRestaurant(restaurant);
    }
  }, [restaurant, updatedRestaurant?.uuid]);

  const setField = (field: string, value: string | string[]) => {
    const [parent, child] = field.split(".");
    if (child) {
      return setUpdatedRestaurant({
        ...updatedRestaurant,
        [parent]: {
          ...(updatedRestaurant[parent as keyof Restaurant] as any),
          [child]: value,
        },
      });
    }

    setUpdatedRestaurant({ ...updatedRestaurant, [field]: value });
  };

  useEffect(() => {
    if (error && setSnackBarMessageProps) {
      setSnackBarMessageProps({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  }, [error, setSnackBarMessageProps, t]);

  const handleScrollTo = (
    id: string,
    e?: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ) => {
    e?.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!hasScrolled) {
      const hash = window.location.hash;
      handleScrollTo(hash.replace("#", ""));
      setHasScrolled(true);
    }
  }, [hasScrolled]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const variables: RestaurantGQLPayload = {
      name: updatedRestaurant.name,
      cuisine: updatedRestaurant.cuisine,
      website: updatedRestaurant.contact.website,
      description: updatedRestaurant.details.description,
      arrivalPolicy: updatedRestaurant.details.arrivalPolicy,
      cancellationPolicy: updatedRestaurant.details.cancellationPolicy,
      contactEmail: updatedRestaurant.contact.email,
      contactName: updatedRestaurant.contact.name,
      cityLocation: updatedRestaurant.city.location,
      country: updatedRestaurant.city.country,
      cityName: updatedRestaurant.city.name,
      contactPhone: updatedRestaurant.contact.phone,
      generalPolicy: updatedRestaurant.details.generalPolicy,
      locationLatitude: Number(updatedRestaurant.location.latitude),
      locationLongitude: Number(updatedRestaurant.location.longitude),
      notes: updatedRestaurant.notes,
      seatCount: parseInt(String(updatedRestaurant.seatCount), 10),
      instagram: updatedRestaurant.contact.instagram,
      addressLine1: updatedRestaurant.city.addressLine1,
      addressLine2: updatedRestaurant.city.addressLine2,
      zipCode: updatedRestaurant.city.zipCode,
    };

    try {
      const { data } = await updateRestaurantInformation({
        variables,
      });

      if (data.updateRestaurantInformation) {
        setRestaurant?.(data.updateRestaurantInformation);
      }

      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error", {
          error: (error as Error).message,
        }),
        severity: Severity.Error,
      });
    }
  };

  if (!updatedRestaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={0} sm={2} className="hidden md:block">
              <div className="flex-col flex gap-4 mt-24 xs:hidden">
                <Link to="#" onClick={(e) => handleScrollTo("account", e)}>
                  <Typography variant="body1">
                    {t("profileEdit.accountInfoTitle")}
                  </Typography>
                </Link>
                <Link to="#" onClick={(e) => handleScrollTo("address", e)}>
                  <Typography variant="body1">
                    {t("profileEdit.addressInfo")}
                  </Typography>
                </Link>
                <Link to="#" onClick={(e) => handleScrollTo("coordinates", e)}>
                  <Typography variant="body1">
                    {t("profileEdit.cordinatesInfo")}
                  </Typography>
                </Link>
                <Link to="#" onClick={(e) => handleScrollTo("contact", e)}>
                  <Typography variant="body1">
                    {t("profileEdit.contactTitle")}
                  </Typography>
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleScrollTo("arrival-instructions", e)}
                >
                  <Typography variant="body1">
                    {t("profileEdit.arrivalInstructionsTitle")}
                  </Typography>
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleScrollTo("cancellation-policy", e)}
                >
                  <Typography variant="body1">
                    {t("profileEdit.cancellationPolicyTitle")}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="h6">{t("profileEdit.title")}</Typography>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="account">
                  {t("profileEdit.accountInfoTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="mame"
                    value={updatedRestaurant.name}
                    onChange={(e) => setField("name", e.target.value)}
                    label={t("profileEdit.restaurantName")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="description"
                    multiline
                    minRows={3}
                    value={updatedRestaurant.details.description}
                    onChange={(e) =>
                      setField("details.description", e.target.value)
                    }
                    label={t("profileEdit.description")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="notes"
                    multiline
                    value={updatedRestaurant.notes}
                    onChange={(e) => setField("notes", e.target.value)}
                    label={t("profileEdit.notes")}
                    required
                    minRows={3}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <div className="flex flex-col md:flex-row gap-4">
                  <Box my={2} flex="auto">
                    <FormControl fullWidth>
                      <InputLabel id="cuisine-label">
                        {t("profileEdit.cuisine")}
                      </InputLabel>
                      <Select
                        labelId="cuisine-label"
                        id="cuisine"
                        multiple
                        label={t("restaurants.cuisine")}
                        value={updatedRestaurant.cuisine ?? []}
                        onChange={(e) => setField("cuisine", e.target.value)}
                      >
                        {Object.values(Cuisine).map((cuisine) => (
                          <MenuItem value={cuisine} key={cuisine}>
                            {cuisine}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex="auto">
                    <TextField
                      id="seatCount"
                      type="number"
                      value={updatedRestaurant.seatCount}
                      onChange={(e) => setField("seatCount", e.target.value)}
                      label={t("profileEdit.seatCount")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="100"
                      margin="normal"
                    />
                  </Box>
                </div>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="address">
                  {t("profileEdit.addressInfo")}
                </Typography>
                <Box>
                  <TextField
                    id="address"
                    type="text"
                    value={updatedRestaurant.city?.addressLine1}
                    onChange={(e) =>
                      setField("city.addressLine1", e.target.value)
                    }
                    label={t("profileEdit.address")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box>
                  <TextField
                    id="address2"
                    type="text"
                    multiline
                    value={updatedRestaurant.city?.addressLine2}
                    onChange={(e) =>
                      setField("city.addressLine2", e.target.value)
                    }
                    label={t("profileEdit.address2")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <div className="flex flex-col md:flex-row gap-4">
                  <Box flex="auto">
                    <TextField
                      id="cityName"
                      type="text"
                      value={updatedRestaurant.city?.name}
                      onChange={(e) => setField("city.name", e.target.value)}
                      label={t("profileEdit.cityName")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="New York"
                      margin="normal"
                    />
                  </Box>
                  <Box flex="auto">
                    <TextField
                      id="location"
                      type="text"
                      value={updatedRestaurant.city?.location}
                      onChange={(e) =>
                        setField("city.location", e.target.value)
                      }
                      label={t("profileEdit.locationName")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder=""
                      margin="normal"
                    />
                  </Box>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <Box flex="1">
                    <TextField
                      id="zipCode"
                      type="number"
                      value={updatedRestaurant.city?.zipCode}
                      onChange={(e) => setField("city.zipCode", e.target.value)}
                      label={t("profileEdit.zipCode")}
                      variant="outlined"
                      fullWidth
                      placeholder="11101"
                      margin="normal"
                    />
                  </Box>
                  <Box flex="auto">
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="country-label">
                        {t("profileEdit.country")}
                      </InputLabel>
                      <Select
                        labelId="country-label"
                        name="country"
                        id="country"
                        label={t("profileEdit.country")}
                        value={updatedRestaurant.city?.country}
                        onChange={(e) =>
                          setField("city.country", e.target.value)
                        }
                      >
                        {Object.values(CountryList).map((country) => (
                          <MenuItem value={country} key={country}>
                            {t(`countries.${country}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="coordinates">
                  {t("profileEdit.cordinatesInfo")}
                </Typography>
                <div className="flex flex-col md:flex-row gap-4">
                  <Box flex="auto">
                    <TextField
                      id="longitude"
                      type="number"
                      value={updatedRestaurant.location?.longitude}
                      onChange={(e) =>
                        setField("location.longitude", e.target.value)
                      }
                      label={t("profileEdit.longitude")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="40.7128"
                      margin="normal"
                    />
                  </Box>
                  <Box flex="auto">
                    <TextField
                      id="latitude"
                      type="number"
                      value={updatedRestaurant.location?.latitude}
                      onChange={(e) =>
                        setField("location.latitude", e.target.value)
                      }
                      label={t("profileEdit.latitude")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="40.7128"
                      margin="normal"
                    />
                  </Box>
                </div>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="contact">
                  {t("profileEdit.contactTitle")}
                </Typography>
                <Box marginTop={1}>
                  <TextField
                    id="contactName"
                    value={updatedRestaurant.contact.name}
                    onChange={(e) => setField("contact.name", e.target.value)}
                    label={t("profileEdit.contactName")}
                    required
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <div className="flex flex-col md:flex-row gap-4">
                  <Box flex="auto">
                    <TextField
                      id="phone"
                      type="tel"
                      value={updatedRestaurant.contact?.phone}
                      onChange={(e) =>
                        setField("contact.phone", e.target.value)
                      }
                      label={t("profileEdit.phone")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="123-456-7890"
                      margin="normal"
                    />
                  </Box>

                  <Box flex="auto">
                    <TextField
                      id="email"
                      type="email"
                      value={updatedRestaurant.contact?.email}
                      onChange={(e) =>
                        setField("contact.email", e.target.value)
                      }
                      label={t("profileEdit.email")}
                      required
                      variant="outlined"
                      fullWidth
                      placeholder="restaurant@example.com"
                      margin="normal"
                    />
                  </Box>
                </div>
                <Box marginTop={1}>
                  <TextField
                    id="website"
                    value={updatedRestaurant.contact.website}
                    onChange={(e) =>
                      setField("contact.website", e.target.value)
                    }
                    label={t("profileEdit.website")}
                    required
                    variant="outlined"
                    type="url"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="instagram"
                    multiline
                    value={updatedRestaurant.contact?.instagram}
                    onChange={(e) =>
                      setField("contact.instagram", e.target.value)
                    }
                    label={t("profileEdit.instagram")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="arrival-instructions">
                  {t("profileEdit.arrivalInstructionsTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="arrivalInstructions"
                    multiline
                    minRows={3}
                    value={updatedRestaurant.details.arrivalPolicy}
                    onChange={(e) =>
                      setField("details.arrivalPolicy", e.target.value)
                    }
                    label={t("profileEdit.arrivalInstructions")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="arrival-instructions">
                  {t("profileEdit.generalPolicy")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="generalPolicy"
                    multiline
                    minRows={3}
                    value={updatedRestaurant.details.generalPolicy}
                    onChange={(e) =>
                      setField("details.generalPolicy", e.target.value)
                    }
                    label={t("profileEdit.generalPolicy")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="cancellation-policy">
                  {t("profileEdit.cancellationPolicyTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="cancellationPolicy"
                    value={updatedRestaurant.details.cancellationPolicy}
                    onChange={(e) =>
                      setField("details.cancellationPolicy", e.target.value)
                    }
                    label={t("profileEdit.cancellationPolicy")}
                    required
                    multiline
                    minRows={3}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <div className="flex flex-col md:flex-row justify-end mt-4 mb-20">
                <Button
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  size="large"
                  className="justify-end"
                >
                  {t("common.save")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
