import { Member } from "@/app/types";
import { Typography, Unstable_Grid2 as Grid } from "@mui/material";

export default function MemberProfile({
  member,
  size = "sm",
  subTitle,
}: {
  member: Member;
  size?: "sm" | "md" | "lg";
  subTitle?: string;
}) {
  let width = "w-12";
  switch (size) {
    case "sm":
      width = "w-12";
      break;
    case "md":
      width = "w-20";
      break;
    case "lg":
      width = "w-44";
      break;
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={3}>
        <img
          src={member?.profilePicture}
          alt={member?.preferredName}
          className={`rounded-full ${width}`}
        />
      </Grid>
      <Grid
        xs={9}
        sx={{
          alignSelf: "center",
        }}
      >
        <Typography variant="body1" noWrap>
          {member?.preferredName}
        </Typography>
        <Typography variant="body2" noWrap>
          {subTitle ?? member?.email}
        </Typography>
      </Grid>
    </Grid>
  );
}
