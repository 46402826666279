import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import Preview from "./Preview";
import NoData from "../../components/common/NoData";
import GettingStarted, { setupItems } from "./GettingStarted";
import ImagePreviewModal from "../../components/common/ImagePreviewModal";
import { Restaurant, MenuCategory, Gallery } from "@/app/types";
import { getRestaurantRoutePrefix } from "../../utils/getRestaurantRoutePrefix.util";
import Spinner from "../../components/common/Spinner";

const sliderProperties = {
  prevArrow: (
    <IconButton style={{ left: -40 }}>
      <KeyboardArrowLeftIcon />
    </IconButton>
  ),
  nextArrow: (
    <IconButton style={{ right: -40 }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  ),
};

const getCurrentStep = (
  restaurant?: Restaurant,
  resyValid?: boolean,
  categories?: MenuCategory[],
  gallery?: Gallery,
) => {
  switch (true) {
    case !gallery?.items?.length:
      return 0;
    case !categories?.length ||
      !categories?.some((category) => category.items.length):
      return 1;
    case !restaurant?.details.arrivalPolicy:
      return 2;
    case !restaurant?.details.cancellationPolicy:
      return 3;
    case !resyValid:
      return 4;
    default:
      return 5;
  }
};
export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { restaurant, gallery, categories, resyValid, isGlobalLoading } =
    useContext(AppContext);

  const [images, setImages] = useState<Gallery["items"]>([]);
  const [currentImage, setCurrentImage] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const currentStepIndex = getCurrentStep(
    restaurant,
    resyValid,
    categories,
    gallery,
  );
  useEffect(() => {
    if (gallery?.items) {
      const itemsCopy = gallery.items.map((item) => ({ ...item }));
      setImages(itemsCopy.sort((a, b) => a.order - b.order));
    }
  }, [gallery?.items]);

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Preview
        isOpen={isPreviewOpen}
        gallery={gallery!}
        restaurant={restaurant}
        setIsOpen={setIsPreviewOpen}
      />
      <ImagePreviewModal
        isOpen={!!currentImage}
        handleClose={() => setCurrentImage("")}
        url={currentImage}
      />
      <Container maxWidth="xl">
        <Grid container marginTop={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">{restaurant.name}</Typography>
            <Typography variant="body1">
              {restaurant.city.name} {restaurant.city.location}{" "}
              {t(`countries.${restaurant.city.country}`)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="md:text-right pt-4 md:pt-0 text-left"
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsPreviewOpen(true)}
            >
              {t("account.previewAction")}
            </Button>
          </Grid>
        </Grid>
        <Grid container marginTop={4}>
          <Grid item xs={12} sm={7}>
            <Box marginRight={2}>
              <Paper elevation={3} className="p-8">
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.accountInfoTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className="md:text-right">
                    <Link to={getRestaurantRoutePrefix(restaurant, "edit")}>
                      <Button variant="contained" color="secondary">
                        {t("common.edit")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>

                <Box marginTop={6}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantName")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">{restaurant.name}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("profileEdit.description")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.details.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("profileEdit.notes")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantCuisine")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.cuisine?.join?.(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantWebsite")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.contact.website}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("profileEdit.contactName")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.contact.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantPhone")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="md:text-right">
                      <Typography variant="body2">
                        {restaurant.contact.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <Box marginY={2}>
                <Accordion
                  expanded={isExpanded}
                  onChange={(e, value) => setIsExpanded(value)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ paddingX: 4, py: 2 }}
                  >
                    <Typography variant="body2">
                      {t("account.gettingStartedText", {
                        current: currentStepIndex,
                        total: setupItems.length,
                      })}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingX: 4 }}>
                    <GettingStarted
                      restaurant={restaurant}
                      currentStepIndex={currentStepIndex}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper elevation={3} className="p-8 sm:ml-4">
              {images.length ? (
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.photoGalleryTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className="md:text-right">
                    <Link
                      to={getRestaurantRoutePrefix(restaurant, "photo-gallery")}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                      >
                        {t("account.manageAction")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              ) : null}
              <Box marginTop={4} padding="0 30px">
                {images.length ? (
                  <Slide
                    slidesToScroll={2}
                    slidesToShow={2}
                    {...sliderProperties}
                  >
                    {images.map((item, index) => (
                      <div className="each-slide-effect mx-1" key={index}>
                        <ImageListItem>
                          <div
                            role="button"
                            onClick={() => setCurrentImage(item.image.data)}
                            title={item.image.alt}
                            className="w-full"
                            style={{
                              height: "240px",
                              background: `url(${item.image.data}) center no-repeat `,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </ImageListItem>
                      </div>
                    ))}
                  </Slide>
                ) : isGlobalLoading ? (
                  <Spinner />
                ) : (
                  <NoData
                    title={t("photoGallery.noPhotosTitle")}
                    message={t("photoGallery.noPhotosText")}
                    topMargin="0"
                    actionTitle={t("photoGallery.addPhotos")}
                    action={() =>
                      navigate(
                        getRestaurantRoutePrefix(restaurant, "photo-gallery"),
                      )
                    }
                  />
                )}
              </Box>
            </Paper>
            <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.arrivalInstructions")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} className="md:text-right">
                    <Typography variant="body2">
                      {restaurant.details.arrivalPolicy}
                    </Typography>
                  </Grid>
                  <Grid item xs={0} sm={1} className="text-right">
                    <Link
                      to={getRestaurantRoutePrefix(
                        null,
                        "edit#arrival-instructions",
                      )}
                    >
                      <IconButton>
                        <ChevronRightIcon sx={{ fontSize: 24 }} />
                      </IconButton>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.cancellationPolicy")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} className="md:text-right">
                    <Typography variant="body2">
                      {restaurant.details.cancellationPolicy}
                    </Typography>
                  </Grid>
                  <Grid item xs={0} sm={1} className="text-right">
                    <Link
                      to={getRestaurantRoutePrefix(
                        null,
                        "edit#edit#cancellation-policy",
                      )}
                    >
                      <IconButton>
                        <ChevronRightIcon sx={{ fontSize: 24 }} />
                      </IconButton>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
