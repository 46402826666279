import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Box, Button, Link, TextField, Typography } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

import { AppContext } from "../../context/AppContext";
import { UIMode } from "../../constants";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";
import { getBackendUrl } from "../../utils/getBackendUrl.util";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { uiMode } = useContext(AppContext);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const failureMessage = t("auth.forgotPasswordFail");
    try {
      setError("");
      setIsLoading(true);
      const res = await fetch(
        `${getBackendUrl()}/forgot-password/password-reset`,
        {
          method: "POST",
          body: new URLSearchParams({
            email,
          }),
        },
      );

      const { status } = await res.json();
      if (status) {
        setFormSubmitted(true);
      } else {
        setError(failureMessage);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setError(`${failureMessage} ${(error as Error).message ?? ""}`);
    } finally {
      setIsLoading(false);
    }
  };

  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  return (
    <div className="m-[10%_auto] max-w-[690px]">
      <Box className="md:px-20 px-4 sm:m-2">
        <Box textAlign={"center"}>
          <img src={logo} alt="Logo" className="w-11 mx-auto mb-10" />
          <Typography
            variant="h2"
            marginBottom={2}
            fontFamily="Kessler"
            className="hidden md:block"
            flexWrap="wrap-reverse"
          >
            {t("auth.forgotPasswordTitle")}
          </Typography>
          <Typography
            variant="h4"
            marginBottom={2}
            fontFamily="Kessler"
            className="block md:hidden"
            flexWrap="wrap-reverse"
          >
            {t("auth.forgotPasswordTitle")}
          </Typography>
          <Typography
            variant="body1"
            marginBottom={4}
            marginX={7}
            className="md:px-7 px-4"
          >
            {t("auth.forgotPasswordDescription")}
          </Typography>
        </Box>
        {formSubmitted ? (
          <div className="text-center">
            <Typography variant="body2" my={8} marginX={7}>
              {t("auth.forgotPasswordSuccess")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              sx={{ width: "100%" }}
              component={Link}
              onClick={() => navigate("/login")}
            >
              {t("auth.backToLogin")}
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={t("auth.email")}
              required={true}
              variant="outlined"
              fullWidth
              placeholder="email@example.com"
              margin="normal"
            />

            <p className="mt-8 text-right">
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                color="primary"
                sx={{ width: "100%" }}
              >
                {t("auth.resetPassword")}
              </Button>
            </p>

            {error && (
              <Alert
                icon={<ErrorIcon fontSize="inherit" />}
                className="mt-4"
                severity="error"
              >
                {error}
              </Alert>
            )}
          </form>
        )}
      </Box>
    </div>
  );
}
