import { createContext, Dispatch, SetStateAction } from "react";
import { Severity, UIMode } from "../constants";
import { Restaurant } from "../types/Restaurant";
import { Gallery, MenuCategory } from "../types";

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface SnackBarMessageProps {
  message: string;
  severity: Severity;
  action?: "resy";
}

interface Context {
  currentUser?: User;
  uiMode?: UIMode;
  restaurant?: Restaurant;
  gallery?: Gallery;
  categories?: MenuCategory[];
  snackBarMessageProps?: SnackBarMessageProps;
  setUiMode?: Dispatch<SetStateAction<UIMode>>;
  resyValid?: boolean;
  setResyValid?: Dispatch<SetStateAction<boolean>>;
  setIsGlobalLoading?: Dispatch<SetStateAction<boolean>>;
  isGlobalLoading?: boolean;
  setCurrentUser?: Dispatch<SetStateAction<User | undefined>>;
  setRestaurant?: Dispatch<SetStateAction<Restaurant | undefined>>;
  setCategories?: Dispatch<SetStateAction<MenuCategory[] | undefined>>;
  setGallery?: Dispatch<SetStateAction<Gallery | undefined>>;
  setSnackBarMessageProps?: Dispatch<
    SetStateAction<SnackBarMessageProps | undefined>
  >;
}

export const AppContext = createContext<Context>({
  uiMode: UIMode.Dark,
});
