import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import Keycloak from "keycloak-js";
import { Alert, Button, Snackbar, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  AppContext,
  SnackBarMessageProps,
  User,
} from "../../context/AppContext";
import { initKeycloak } from "../../../keycloak";
import { LocalStorageKeys, UIMode } from "../../constants";
import { Restaurant, MenuCategory, Gallery } from "../../types/";

import { ApplicationRouter } from "./ApplicationRouter";
import { AppData } from "./AppData";

export default function AppContainer() {
  const didInit = useRef(false);
  const { t } = useTranslation();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [snackBarMessageProps, setSnackBarMessageProps] =
    useState<SnackBarMessageProps>();
  const [restaurant, setRestaurant] = useState<Restaurant | undefined>();
  const [resyValid, setResyValid] = useState<boolean>(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const [gallery, setGallery] = useState<Gallery | undefined>();
  const [categories, setCategories] = useState<MenuCategory[] | undefined>();
  const keycloak = useRef<Keycloak | undefined>();

  let currentUiMode = prefersDarkMode ? UIMode.Dark : UIMode.Light;
  const [uiMode, setUiMode] = useState(currentUiMode);
  moment.locale(i18next.language);

  const initAuth = async () => {
    const token = localStorage.getItem(LocalStorageKeys.Token);
    const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
    if (!token || !refreshToken) {
      return;
    }

    const { keycloak } = await initKeycloak({
      access_token: token,
      refresh_token: refreshToken,
    });

    setCurrentUser!(keycloak.tokenParsed! as User);
    return keycloak;
  };

  useEffect(() => {
    if (currentUiMode !== uiMode) {
      setUiMode(currentUiMode);
    }

    if (!didInit.current) {
      initAuth().then((kc) => {
        keycloak.current = kc;
      });
      didInit.current = true;
    }
  }, [currentUiMode, uiMode]);

  const theme = createTheme({
    typography: {
      fontFamily: "Enduro",
      button: {
        textTransform: "none",
        backgroundColor: "none",
      },
    },
    palette: {
      primary: {
        main: uiMode === UIMode.Dark ? "#fff" : "#000",
        light: uiMode === UIMode.Dark ? "#000" : "#fff",
        dark: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
      },
      action: {
        active: uiMode === UIMode.Dark ? "#fff" : "#000",
      },
      background: {
        default: uiMode === UIMode.Dark ? "#000" : "#F9FAFB",
        paper: uiMode === UIMode.Dark ? "#111" : "#fff",
      },
      secondary: {
        main: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
        light: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
        dark: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
      },
      mode: uiMode,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AppContext.Provider
        value={{
          uiMode,
          currentUser,
          restaurant,
          setCurrentUser,
          setRestaurant,
          setResyValid,
          resyValid,
          setCategories,
          categories,
          setGallery,
          gallery,
          setUiMode,
          setIsGlobalLoading,
          isGlobalLoading,
          setSnackBarMessageProps,
        }}
      >
        <main>
          <ApplicationRouter />
          {keycloak.current?.authenticated && <AppData />}
          <Snackbar
            open={!!snackBarMessageProps}
            autoHideDuration={6000}
            onClose={() => setSnackBarMessageProps(undefined)}
          >
            <Alert
              severity={snackBarMessageProps?.severity}
              variant="filled"
              sx={{ width: "100%" }}
              action={
                snackBarMessageProps?.action === "resy" && (
                  <Button
                    size="small"
                    aria-label="close"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      (window.location.href = `/account/profile/connect-platform/resy`)
                    }
                  >
                    {t("platform.resy.connectResy")}
                  </Button>
                )
              }
            >
              {snackBarMessageProps?.message}
            </Alert>
          </Snackbar>
        </main>
      </AppContext.Provider>
    </ThemeProvider>
  );
}
